<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">My Profile</h1>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="#3F51B5" dark flat dense>
                            <v-app-bar-title>My profile</v-app-bar-title>
                            <v-spacer/>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon @click="refreshUserProfile" v-bind="attrs" v-on="on">
                                        <font-awesome-icon :icon="['far', 'sync-alt']" fixed-width/>
                                    </v-btn>
                                </template>
                                Refresh
                            </v-tooltip>
                            <v-menu offset-y left open-on-click open-on-hover close-delay="100">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                <v-list class="ma-0 pa-0">
                                    <v-list-item-group>
                                    <v-list-item :to="{ name: 'profile-settings' }">
                                        <v-list-item-icon>
                                            <font-awesome-icon :icon="['fas', 'cog']" color="blue" fixed-width/>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Settings</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-menu>
                        </v-app-bar>
                        <v-card-text>
                            <p class="text-overline mb-0">Name</p>
                            <p class="mb-0 pb-0">
                                <span>{{ displayName }}</span>
                                <v-btn class="blue--text text--darken-2 no-print" @click="dialogEditDisplayName = true" icon>
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                                </v-btn>
                            </p>
                            <p class="text-overline mb-0 mt-6">
                                Email
                                <v-btn class="blue--text text--darken-2 no-print" @click="addEmail" icon>
                                    <font-awesome-icon :icon="['fas', 'plus']" fixed-width size="1x"/>
                                </v-btn>
                            </p>
                            <p class="mb-0 pb-0">
                                <span>{{ email }}</span>
                                <!-- <v-btn class="blue--text no-print" @click="dialogEditDisplayName = true" icon>
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                                </v-btn> -->
                            </p>
                            <!-- <p class="text-overline mb-0 mt-8">Security</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'profile-settings-session' }">Session info</router-link>
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'profile-settings-authz' }">Roles and permits</router-link>
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'profile-settings-authn-loginfront' }">Login settings</router-link>
                            </p>
                            <p class="text-overline mb-0 mt-8">Other</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'profile-delete' }">How to delete my profile and related data</router-link>
                            </p> -->
                        </v-card-text>
                    </v-card>
                    <v-dialog v-model="dialogEditDisplayName" max-width="600">
                        <v-card elevation="4" class="pa-5">
                            <v-card-text class="text-h6 pa-0 pb-3">Edit display name</v-card-text>
                            <v-row justify="center" class="pt-3">
                                <v-col cols="12">
                                    <div class="font-weight-light text-body-1">
                                        <p>We will use this name to address you on our website and when we communicate by email, telephone, or letters.</p>
                                        <p>You can use your full name, or only your first name, or a nickname if you prefer.</p>
                                        <p>For example, "Sparky" or "Doctor Who".</p>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-form @submit="editDisplayName" onSubmit="return false;" @keyup.enter.native="editDisplayName">
                                <v-text-field
                                    v-model=editableDisplayName
                                    ref="displayNameInput"
                                    label="Your display name"
                                    :rules="nameRules"
                                    outlined
                                ></v-text-field>
                                <v-card-actions>
                                    <v-row justify="center">
                                    </v-row>
                                </v-card-actions>
                            </v-form>
                            <v-row justify="center">
                                <v-btn elevation="4" class="blue white--text" @click="editDisplayName" :disabled="!isEditDisplayNameFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditDisplayName = false">Cancel</v-btn>
                            </v-row>
                        </v-card>
                    </v-dialog>
                    <!-- <p class="text-overline mb-0 mt-8">Volumes</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'user-search-volume', params: { userId: this.user.id } }">See all volumes</router-link>
                    </p> -->
                </v-col>
            </v-row>

            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="4" class="pa-5">
                        <v-card-text class="text-h6 text-center pa-0 pb-3">Name</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3">
                            <v-col cols="12">
                                <div class="font-weight-light text-body-1 text-center mx-5">
                                    <p>We will use this name to address you on our website and when we communicate by email, telephone, or letters.</p>
                                    <p>For example, "Sparky" or "Doctor Who"</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mx-5">
                            <v-text-field
                                outlined
                                v-model=editableDisplayName
                                color="blue"
                                label="Your nickname, first name, or title"
                                :rules="nameRules"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="center">
                            <v-btn elevation="4" class="blue white--text" @click="editDisplayName" :disabled="!isIdentityNameComplete">
                                <font-awesome-icon icon="check" fixed-width/>
                                Save
                            </v-btn>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row> -->
            <!-- <p>If you change this address we will send a verification email and you must click on the link in the email to confirm the change.</p> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="4" class="pa-5">
                        <v-card-text class="text-h6 text-center pa-0 pb-3">Email</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3">
                            <v-col cols="12">
                                <div class="font-weight-light text-body-1 text-center mx-5">
                                    <p>We will use this email address to send you electronic messages.</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mx-5">
                            <v-text-field
                                outlined
                                v-model=editableEmail
                                color="blue"
                                label="Your email address"
                                :rules="emailRules"
                                readonly
                                disabled
                            ></v-text-field>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0">Other</p>
                    <p>
                        <router-link :to="{ path: '/user/delete', query: { acct: this.$route.query.acct } }">How to delete this profile</router-link>
                    </p>
                </v-col>
            </v-row> -->
        </v-col>
    </v-row>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
import { isValidName, isValidEmail, isValidPhone } from '@/sdk/input';

export default {
    data: () => ({
        dialogDeleteAccount: false,
        // nameRules: [
        //     (v) => (typeof v === 'string' && v.length >= 1) || 'Must not be empty',
        // ],
        nameRules: [
            (v) => isValidName(v) || 'Enter your name',
        ],
        emailRules: [
            (v) => !v || isValidEmail(v) || 'Enter your email address',
        ],
        phoneRules: [
            (v) => !v || isValidPhone(v) || 'Enter your phone number',
        ],
        // snackbarIdentityNameUpdated: false,
        // snackbarIdentityEmailUpdated: false,
        dialogEditDisplayName: false,
        editableDisplayName: null,
        editableEmail: null,
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        displayName() {
            if (typeof this.user.display_name === 'string' && this.user.display_name.trim().length > 0) {
                return this.user.display_name;
            }
            return '(unknown)';
        },
        email() {
            if (typeof this.user.email === 'string' && this.user.email.trim().length > 0) {
                return this.user.email;
            }
            return '(unknown)';
        },
        // name: {
        //     get() {
        //         if (this.user.name) {
        //             return this.user.name;
        //         }
        //         return '';
        //     },
        //     set(value) {
        //         this.$store.dispatch('editUser', { name: value });
        //     },
        // },
        // email: {
        //     get() {
        //         if (this.user.email) {
        //             return this.user.email;
        //         }
        //         return '';
        //     },
        //     set(value) {
        //         // TODO: need email verification first ... let server set it after user clicks link ... server should reject editUser { email } , it should make available a workflow for changing email that a client can initiate
        //         this.$store.dispatch('editUser', { email: value });
        //     },
        // },
        isEditDisplayNameFormComplete() {
            return this.user
            && this.editableDisplayName
            && this.editableDisplayName !== this.user.display_name;
        },
        // isIdentityNameComplete() {
        //     return this.user
        //     && this.editableDisplayName
        //     && this.editableDisplayName !== this.user.name;
        // },
        // isIdentityEmailComplete() {
        //     return this.user
        //     && this.editableEmail
        //     && this.editableEmail !== this.user.email;
        // },
    },
    watch: {
        isAuthenticatedReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
        dialogEditDisplayName(display) {
            if (display) {
                this.editableDisplayName = this.user.display_name;
                setTimeout(() => { this.$nextTick(() => this.$refs.displayNameInput.focus()); }, 1);
            }
        },
        // email(val) {
        //     this.editableEmail = val;
        // },
        dialogDeleteAccount(val) {
            if (!val) {
                this.closeDialogDeleteAccount();
            }
        },
    },
    methods: {
        init() {
            console.log('Preferences.vue: init');
        },
        async editDisplayName() {
            // try {
            //     this.$store.commit('loading', { editDisplayName: true });
            const isEdited = await this.$store.dispatch('editCurrentUser', { display_name: this.editableDisplayName });
            // this.name = this.editableDisplayName;
            // this.snackbarIdentityNameUpdated = true;
            if (isEdited) {
                await this.$store.dispatch('loadUser');
                this.dialogEditDisplayName = false;
                this.$bus.$emit('snackbar', { type: 'success', message: 'Updated name' });
            } else {
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update name' });
            }
            // } catch (err) {
            //     console.error('editDisplayName failed', err);
            //     this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update name' });
            // } finally {
            //     this.$store.commit('loading', { editDisplayName: false });
            // }
        },
        // editEmail() {
        //     this.email = this.editableEmail;
        //     this.snackbarIdentityEmailUpdated = true;
        // },
        async deleteAccount() {
            this.$store.commit('loading', { deleteAccount: true });
            const response = await this.$client.user(this.$store.state.session.userId).user.delete();
            if (response.isDeleted) {
                await this.$store.dispatch('logout');
                this.$router.push('/');
                console.log('account.vue: deleteAccount success: %o', response);
            } else {
                console.log('account.vue: deleteAccount failed: %o', response);
                // this.snackbarAccountDeleteFailed = true;
            }
            this.closeDialogDeleteAccount();
            this.$store.commit('loading', { deleteAccount: false });
        },
        closeDialogDeleteAccount() {
            this.dialogDeleteAccount = false;
        },
        async refreshUserProfile() {
            this.$store.commit('loading', { refreshUserProfile: true });
            const response = await this.$client.user(this.$store.state.session.userId).user.check({ item: 'profile' });
            console.log(`refreshUserProfile edited? ${response.isEdited}`);
            if (response.isEdited) {
                await this.$store.dispatch('refresh');
                this.$bus.$emit('snackbar', { type: 'success', headline: 'Refreshed profile' });
            } else {
                this.$bus.$emit('snackbar', { type: 'info', headline: 'No changes to profile' });
            }
            this.$store.commit('loading', { refreshUserProfile: false });
        },
        async addEmail() {
            this.$router.push({ name: 'profile-add-email' });
        },
    },
    mounted() {
    },
};
</script>
